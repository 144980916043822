.logo_1 {
    display: block;
}

.logo_2 {
    display: none;
}

@media screen and (max-width: 740px) {
    .logo_1 {
        display: none;
    }

    .logo_2 {
        display: block;
    }
}

@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-30%);
    }
  }
  
  .animate-marquee-container {
    display: flex;
    width: 100%;
    animation: marquee 15s linear infinite;
  }

@media screen and (max-width: 740px) {
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-150%);
    }
  }
  
  .animate-marquee-container {
    display: flex;
    width: 100%;
    animation: marquee 15s linear infinite;
  }
}