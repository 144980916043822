@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: 'Rubik';
    background-color: #d0cfcd;
}

.Archivo {
    font-family: 'Archivo';
    font-weight: 900;
}

.header {
    /*background-image: url("bg-photo/reme-cuerpo-grupal-banner-web.webp");*/
    background-image: url("bg-photo/hoodie-banner-web.webp");
}

@media screen and (max-width: 740px) {
    .header {
        /* background-image: url("bg-photo/reme-cuerpo-grupal-banner-web-mobile.webp"); */
        background-image: url("bg-photo/hoodie-banner-web-mobile.webp");
    }
}

.letter-shadow {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }


.typewriter-text span {
    display: inline;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  